.work-wrapper {
  grid-template: repeat(8, 1fr)/repeat(4, 1fr);
  grid-template-areas: "video video video thumbnail" "video video video thumbnail" "video video video thumbnail" "video video video thumbnail" "video video video thumbnail" "video video video thumbnail" "infos infos infos thumbnail" "infos infos infos thumbnail";
}

.video-player-container {
  position: relative;
  grid-area: video;
  width: calc(100% - 4rem);
  height: calc(100% - 2rem);
  margin: 2rem 0 0 4.5rem;
  transition: 0.5s opacity ease-in-out;
}
.video-player-container .my-video {
  border-radius: 0.5rem;
  height: 100%;
  width: 100%;
  border: 2px solid black;
  background: black;
  -o-object-fit: contain;
  object-fit: contain;
}

.video-loader-container {
  transform: translate(-50%, -50%);
  position: absolute;
  top: 50%;
  left: 50%;
  display: none;
  justify-content: center;
  align-items: center;
  color: #aaa;
  font-family: arial;
  font-weight: bolder;
}
.video-loader-container.loader-apparition {
  display: flex;
}

.link {
  --loader-font-size: 2.5rem;
  font-size: var(--loader-font-size);
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-animation: 1s linear forwards text-container-apparition;
  animation: 1s linear forwards text-container-apparition;
}

.my-text {
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  -webkit-animation: 9s linear infinite 1s text-container-animation;
  animation: 9s linear infinite 1s text-container-animation;
}

.my-text-content {
  text-align: center;
  display: inline-block;
  width: 0;
  overflow: hidden;
  -webkit-animation: 9s linear infinite 1s text-apparition;
  animation: 9s linear infinite 1s text-apparition;
}

.my-text-1 {
  --width: calc(var(--loader-font-size) * 1.15);
}

.my-text-2 {
  --width: calc(var(--loader-font-size) * 5.3);
  -webkit-animation-delay: 4s;
  animation-delay: 4s;
}

.my-text-3 {
  --width: calc(var(--loader-font-size) * 6);
  -webkit-animation-delay: 7s;
  animation-delay: 7s;
}

@-webkit-keyframes text-container-apparition {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes text-container-apparition {
  0%, 80% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-webkit-keyframes text-container-animation {
  0%, 33%, 66%, 100% {
    width: 0;
  }
  1.1%, 31.9% {
    width: calc(var(--loader-font-size) * 1.15);
  }
  38%, 61% {
    width: calc(var(--loader-font-size) * 5.3);
  }
  71.6%, 94.4% {
    width: calc(var(--loader-font-size) * 6);
  }
}
@keyframes text-container-animation {
  0%, 33%, 66%, 100% {
    width: 0;
  }
  1.1%, 31.9% {
    width: calc(var(--loader-font-size) * 1.15);
  }
  38%, 61% {
    width: calc(var(--loader-font-size) * 5.3);
  }
  71.6%, 94.4% {
    width: calc(var(--loader-font-size) * 6);
  }
}
@-webkit-keyframes text-apparition {
  0%, 33% {
    width: 0;
  }
  .1%, 32.9% {
    width: var(--width);
  }
}
@keyframes text-apparition {
  0%, 33% {
    width: 0;
  }
  .1%, 32.9% {
    width: var(--width);
  }
}
.my-video:hover + .video-controls,
.video-controls:hover {
  opacity: 1;
  display: flex;
}

.video-controls {
  position: absolute;
  height: 48px;
  display: none;
  opacity: 0;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  transition: 0.25s opacity ease-in;
  width: 336px;
  justify-content: space-around;
  padding: 0.5rem;
  background: hsla(0, 0%, 0%, 0.25);
  border: 2px solid hsla(0, 0%, 100%, 0.15);
  border-radius: 5px;
}
.video-controls button {
  border: none;
  background: none;
  color: var(--fontcol1);
  font-size: 24px;
  cursor: pointer;
}
.video-controls button:hover {
  color: var(--ctacol);
}

.video-thumb-container::-webkit-scrollbar {
  width: auto;
  height: auto;
}

.video-thumb-container::-webkit-scrollbar-track {
  background: hsla(285, 90%, 100%, 0.25);
  border-radius: 0.5rem;
}

.video-thumb-container::-webkit-scrollbar-thumb {
  background: hsla(285, 90%, 100%, 0.5);
  border-radius: 0.5rem;
  cursor: pointer;
}

.video-thumb-container::-webkit-scrollbar-thumb:hover {
  background: hsla(285, 90%, 100%, 0.75);
}

.video-thumb-container {
  grid-area: thumbnail;
  background: hsla(180, 100%, 80%, 0.15);
  margin: 2rem 4.5rem 2rem 2rem;
  border-radius: 0.5rem;
  overflow-y: scroll;
}
.video-thumb-container .video-thumb {
  /* new CSS*/
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  /*end */
  padding: 1rem 0.5rem;
}
.video-thumb-container .video-thumb .video-thumb-rank {
  /* new CSS */
  position: absolute;
  font-size: 6rem;
  color: transparent;
  color: var(--ctacol);
  opacity: 0.75;
  pointer-events: none;
  /* end */
}
.video-thumb-container .video-thumb .video-thumb-img {
  width: 95%;
  height: 95%;
  -o-object-fit: contain;
  object-fit: contain;
  cursor: pointer;
  border-radius: 0.2rem;
  grid-area: video;
}
.video-thumb-container .video-thumb .video-thumb-name {
  display: none;
  grid-area: title;
  font-size: 1.15rem;
}

.video-infos-container {
  grid-area: infos;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-areas: "name links" "descr descr";
  width: calc(100% - 4rem);
  height: calc(100% - 4rem);
  border-radius: 0.5rem;
  margin: 2rem 0 2rem 4.5rem;
  background: hsla(180, 100%, 80%, 0.15);
  font-family: var(--fontfam2);
}
.video-infos-container .video-name {
  grid-area: name;
  font-family: var(--fontfam1);
  font-size: max(24px, 2rem);
  margin-left: 2rem;
  display: flex;
  align-items: center;
}
.video-infos-container .video-links {
  grid-area: links;
  font-family: var(--fontfam2);
  display: flex;
  align-items: center;
}
.video-infos-container .video-links a {
  margin-right: 24px;
  font-size: 24px;
}
.video-infos-container .video-links a,
.video-infos-container .video-links a:link,
.video-infos-container .video-links a:visited,
.video-infos-container .video-links a:focus,
.video-infos-container .video-links a:hover,
.video-infos-container .video-links a:active {
  color: var(--fontcol1);
}
.video-infos-container .video-links a:hover {
  color: var(--ctacol);
}
.video-infos-container .video-descr {
  grid-area: descr;
  margin-left: 1rem;
  font-size: max(13.8px, 1.15rem);
  line-height: 1.25;
}