:root {
  --avatar-length-unit: 0.25rem;
  --avatar-clip-value: 0%;
}

.home-wrapper {
  grid-template: repeat(2, 1fr)/repeat(2, auto);
  grid-template-areas: "name avatar" "links avatar";
}

.introducing-myself {
  position: relative;
  grid-area: name;
  justify-self: center;
  align-self: center;
  margin-left: 2rem;
}
.introducing-myself .my-name {
  font-family: var(--fontfam1);
  font-size: 7.5rem;
}
.introducing-myself .my-role {
  font-size: 3.75rem;
  text-align: center;
  font-family: var(--fontfam2);
  margin-top: -0.75rem;
  border-top: 0.25rem solid var(--fontcol2);
  color: var(--fontcol2);
}

.status-container {
  position: relative;
  grid-area: links;
  align-self: flex-start;
  justify-self: center;
  margin-left: 2rem;
  height: 33%;
}
.status-container .status {
  position: relative;
  overflow-y: hidden;
  height: 4.5rem;
  font-family: var(--fontfam2);
  font-size: 2.75rem;
  transition: height 0.25s ease-in-out;
}
.status-container .reload-status {
  position: absolute;
  bottom: 15%;
  left: calc(50% - 1.5rem);
  width: auto;
  font-size: 1.5rem;
  color: var(--ctacol);
  border: none;
  background: none;
  transition: transform 0.25s linear, 0.05s font-size ease-in-out;
  cursor: pointer;
}
.status-container .reload-status:hover {
  font-size: 1.75rem;
}

.social-network {
  grid-area: links;
  align-self: flex-end;
  padding: 2rem;
  height: 10vh;
  width: 66%;
  font-size: 3.5rem;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}
.social-network a {
  justify-self: center;
  align-self: center;
  transition: 0.05s font-size ease-in-out;
}
.social-network a:link,
.social-network a:visited,
.social-network a:active {
  color: var(--fontcol1);
}
.social-network a:hover {
  font-size: 3.75rem;
  color: var(--ctacol);
}

.my-avatar {
  position: relative;
  bottom: 2rem;
  right: 2rem;
  grid-area: avatar;
  width: 30rem;
  height: auto;
  align-self: flex-end;
  justify-self: center;
}
.my-avatar img {
  pointer-events: none;
}
.my-avatar .avatar-body {
  width: inherit;
  height: auto;
  object-fit: contain;
}
.my-avatar .avatar-head {
  position: absolute;
  bottom: 49.41%;
  left: 22.551%;
  width: 50.157%;
}
.my-avatar .avatar-head1 {
  -webkit-clip-path: polygon(0% calc(var(--avatar-clip-value)), 100% calc(var(--avatar-clip-value)), 100% 20%, 0% 20%, 0% calc(20% + var(--avatar-clip-value)), 100% calc(20% + var(--avatar-clip-value)), 100% 40%, 0% 40%, 0% calc(40% + var(--avatar-clip-value)), 100% calc(40% + var(--avatar-clip-value)), 100% 60%, 0% 60%, 0% calc(60% + var(--avatar-clip-value)), 100% calc(60% + var(--avatar-clip-value)), 100% 80%, 0% 80%, 0% calc(80% + var(--avatar-clip-value)), 100% calc(80% + var(--avatar-clip-value)), 100% 100%, 0% 100%);
  clip-path: polygon(0% calc(var(--avatar-clip-value)), 100% calc(var(--avatar-clip-value)), 100% 20%, 0% 20%, 0% calc(20% + var(--avatar-clip-value)), 100% calc(20% + var(--avatar-clip-value)), 100% 40%, 0% 40%, 0% calc(40% + var(--avatar-clip-value)), 100% calc(40% + var(--avatar-clip-value)), 100% 60%, 0% 60%, 0% calc(60% + var(--avatar-clip-value)), 100% calc(60% + var(--avatar-clip-value)), 100% 80%, 0% 80%, 0% calc(80% + var(--avatar-clip-value)), 100% calc(80% + var(--avatar-clip-value)), 100% 100%, 0% 100%);
}
.my-avatar .avatar-head2 {
  -webkit-clip-path: polygon(0% 0%, 100% 0%, 100% calc(0% + var(--avatar-clip-value)), 0% calc(0% + var(--avatar-clip-value)), 0% 20%, 100% 20%, 100% calc(20% + var(--avatar-clip-value)), 0% calc(20% + var(--avatar-clip-value)), 0% 40%, 100% 40%, 100% calc(40% + var(--avatar-clip-value)), 0% calc(40% + var(--avatar-clip-value)), 0% 60%, 100% 60%, 100% calc(60% + var(--avatar-clip-value)), 0% calc(60% + var(--avatar-clip-value)), 0% 80%, 100% 80%, 100% calc(80% + var(--avatar-clip-value)), 0% calc(80% + var(--avatar-clip-value)));
  clip-path: polygon(0% 0%, 100% 0%, 100% calc(0% + var(--avatar-clip-value)), 0% calc(0% + var(--avatar-clip-value)), 0% 20%, 100% 20%, 100% calc(20% + var(--avatar-clip-value)), 0% calc(20% + var(--avatar-clip-value)), 0% 40%, 100% 40%, 100% calc(40% + var(--avatar-clip-value)), 0% calc(40% + var(--avatar-clip-value)), 0% 60%, 100% 60%, 100% calc(60% + var(--avatar-clip-value)), 0% calc(60% + var(--avatar-clip-value)), 0% 80%, 100% 80%, 100% calc(80% + var(--avatar-clip-value)), 0% calc(80% + var(--avatar-clip-value)));
}
.my-avatar .pull-me-container {
  position: absolute;
  top: 55%;
  left: 50%;
  height: 6rem;
  cursor: grab;
}
.my-avatar .pull-me {
  position: absolute;
  height: 2.5rem;
  width: 2.75rem;
  background: var(--ctacol);
  font-size: 1.5rem;
  color: var(--fontcol2);
  display: grid;
  place-content: center;
  border: none;
  border-radius: 0 0 25px 25px;
}
.my-avatar .pull-me-anim {
  animation: 3s linear infinite pull-me-cta;
}
@keyframes pull-me-cta {
  0%, 45%, 55%, 100% {
    transform: none;
  }
  47%, 53% {
    transform: translateY(20%);
  }
  50% {
    transform: translateY(-5%);
  }
}

.chest-container {
  position: relative;
  height: 12.5rem;
  width: 10rem;
  cursor: pointer;
  grid-area: avatar;
  justify-self: flex-end;
  align-self: flex-end;
  right: calc(50% + 15rem);
  transition: 0.5s transform 0.5s cubic-bezier(0.2, -0.1, 1, -0.5);
}
.chest-container .chest-top {
  position: relative;
  height: 45%;
  width: 100%;
}
.chest-container .chest-top .chest-top-front {
  position: absolute;
  bottom: 0;
  height: 90%;
  width: 100%;
  background: var(--ctacol);
  border-radius: 100rem 100rem 0 0;
  transition: transform 0.25s ease-in-out;
  transform-origin: bottom left;
}
.chest-container .open {
  transform: rotate(-90deg);
}
.chest-container .chest-bottom {
  height: 40%;
  width: 100%;
  background: var(--ctacol);
  border-radius: 0% 0% 100rem 100rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

.chest-closed {
  transform: scale(0);
}

.accessory-container {
  position: absolute;
  cursor: -webkit-grab;
  cursor: grab;
  transform: translateX(-50%);
  transition: bottom 1s ease-in-out, left 1s ease-in-out;
}
.accessory-container .avatar-accessory {
  pointer-events: none;
  width: 100%;
  height: auto;
  transform: scale(0);
  transition: transform 1s ease-in-out;
}
.accessory-container .accessory-ejection {
  transform: scale(1);
}

.accessory-container1 {
  right: 32vw;
  bottom: 39%;
}

.accessory-container2 {
  right: 32vw;
  bottom: 44%;
}

.accessory-container3 {
  right: 35vw;
  bottom: 42%;
}

.accessory-container4 {
  right: 31vw;
  bottom: 39%;
}

.accessory-container5 {
  right: 34vw;
  bottom: 44%;
}

.accessory-container6 {
  right: 31vw;
  bottom: 38%;
}

.accessory-container7 {
  right: 31vw;
  bottom: 37%;
}

.accessory-container8 {
  right: 31vw;
  bottom: 42%;
}

.accessory-container9 {
  right: 33vw;
  bottom: 46%;
}

.accessory-container10 {
  right: 31vw;
  bottom: 44%;
}

.accessory-container11 {
  right: 31vw;
  bottom: 36%;
}

.accessory-hidden {
  pointer-events: none;
  bottom: 0.5rem;
}

.remind-cta {
  position: relative;
  top: -2.25rem;
  height: 4.5rem;
  width: 4.5rem;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.remind-cta .circle {
  position: absolute;
  border-radius: 100%;
  height: inherit;
  width: inherit;
  border: 0.5rem solid var(--fontcol2);
  opacity: 0;
  animation: 3s linear infinite remind-cta;
}
.remind-cta .circle:nth-child(0) {
  animation-delay: 0s;
}
.remind-cta .circle:nth-child(1) {
  animation-delay: 0.25s;
}
.remind-cta .circle:nth-child(2) {
  animation-delay: 0.5s;
}
.remind-cta .circle:nth-child(3) {
  animation-delay: 0.75s;
}

@keyframes remind-cta {
  0%, 50% {
    opacity: 0;
    transform: scale(1);
  }
  70% {
    transform: scale(0.2);
    opacity: 0.7;
  }
  100% {
    transform: scale(0.2);
    opacity: 0;
  }
}
.react-draggable-dragged {
  cursor: grab;
}

.react-draggable-dragging {
  cursor: -webkit-grabbing;
  cursor: grabbing;
}